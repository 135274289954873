var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "scan-detail"
  }, [_c('div', {
    staticClass: "info-list"
  }, [_c('div', {
    staticClass: "info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.result))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };